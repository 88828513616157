.questionsInExamPage {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media print {
    .questionsInExamPage {
        display: block;
    }
}

@media (min-width: 400px) {
    .dateTable {
        max-width: 360px;
    }
}

@media (min-width: 750px) {
    .dateTable {
        min-width: 700px;
    }
}