.questionInfoBox{
    margin-bottom: 3px;
    display: flex;
}

.questionInfoLabel{
    width: 100px;
    background-color: rgb(241, 199, 199);
    margin-right: 10px;
    padding-left: 2px;
}