.unsignedScreen{
    /* border: solid red 1px; */
    margin: 20px;
    border-radius: 10px;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
}

.mainImage{
    max-width: 100%;
    box-shadow: rgb(0 0 0 / 68%) 8px 10px 15px;

}


.homePageExamSuggestionsBox {
    display: flex;
    flex-wrap: wrap;
   
}