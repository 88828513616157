.examIntroBox {
    /* width: 200px; */
    height: 200px;
    background-color: #b9e9c1;
    margin: 15px;
    padding: 5px;
    border-radius: 10px;
    /* flex: 1; */
    width: 100%;
    display: flex;
    font-size: small;
    /* max-width: 400px; */
  }
  
  @media (min-width: 700px) {
    .examIntroBox {
      flex: 1;
      min-width: 450px;
    }
  }
  
  .examIntroBox .examImage {
    width: 200px;
    border-radius: 12px;
    margin-right: 20px;
    /* height: 100%; */
  }
  
  .examIntroBox .examDetailsBox {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .examIntroBox .header {
    font-size: medium;
  }
  
  .examIntroBox .desctiption {
  }
  
  .examIntroBox .status {
  }
  
  .examIntroBox .startDate {
  }
  
  .examIntroBox .endDate {
  }
  
  .examIntroBox .examButtonsContainer{
  
    width: 400px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }