.loginPageLink {
  text-decoration: underline;
  font-weight: 700;
}

.doYouHaveAccountBox {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 52px;
  border-top: solid 1px #cfccd7;
  padding-top: 10px;
  align-items: flex-end;
}