.examCardBox {
  /* height: 400px;
  width: calc(50% - 1.5rem);
  background-color: #b9e9c1;
  padding: 5px;
  display: flex;
  flex-direction: column;
  font-size: small;
  border: solid red 1px;
  align-items: center; */

  background-color: #fafafa;
  transition: background-color 0.4s;
  color: #393b42;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-size: 0.7777rem;
  line-height: 1.5;
  position: relative;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: rgb(0 0 0 / 28%) 4px 6px 6px;
}

.examCardBox:hover {
  background-color: #dfdbdb;
}

.examCardBox .examImageWrapper {
  height: 150px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}
.examCardBox .examImage {
  transform: translateZ(0) rotate(0.001deg) scale(1.01);
  transition: transform 0.4s;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.examCardBox .examDetailsBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.examCardBox .header {
  font-size: medium;
  font-weight: 600;
  margin-bottom: 10px;
}

.examCardBox .desctiption {
}

.examCardBox .status {
}

.examCardBox .startDate {
}

.examCardBox .endDate {
}

.examCardBox .examButtonsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 10px;
}

.examButtonsContainer div {
  padding: 0.7em 1em;
  font-weight: 700;
  transition-duration: 0.3s;
  transition-property: background-color, border-color, color, text-shadow;
  transition-timing-function: linear;
  background-color: #de00a5;
  color: #fff;
}

.examButtonsContainer div:hover {
  background: #5a62cf;
}
