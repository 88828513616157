.templateBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

#container {
  /* border:solid blue; */
}
