.appBarBox{
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    position: sticky;
    box-shadow: rgb(0 0 0 / 18%) 0px 3px 10px;
    top: 0;
    height: 55px;
    width: 100%;
    z-index: 1100;
    /* position="fixed" style={{bottom:0, height:30, top:'auto'}} */
 
}

@media (max-width:768px) {
  .appBarBox{
    position: fixed;
    /* margin: -50px; */
    bottom: 0;
    top:auto;
}
}
.logo{
       /* background-color: #175454; */
       max-width: 180px;
}

.search {
    position: 'relative';
    border-radius: 2px;
  }
  .searchIcon {
    padding: 2px;
    height: 100%;
    position: 'absolute';
    pointer-events: 'none';
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
  }

  .authenticatedUserButtonsBox{
    display: flex;
    gap: 12px;
  }

  .avatarDrawer{
    padding: 20px;
    width: 250px;
    display: flex;
    flex-direction: column;
  }

  .drawerUpperBox{
    display: flex;
  }

  .drawerNameContainer{
    display: flex;
    flex-direction: column;
    height: 100px;
  }