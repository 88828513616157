.templateBox{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 30px);
  margin-bottom: 30px;
}

#container{
  /* border:solid blue; */
}